
export var generalData= {
    value: 'generalData',
    label: 'General Data',
    type: 'standard',
     tabData: [ 
      {
        label: 'Vendor Article Number',
        type: 'text',
        model: 'vendorArticleNumber ',
        size:4
        
      },
      {
        label: '1st Rem/Exped ',
        type: 'text',
        model: '1stRemExped',
        size:4
      },
      {
        label: '2nd Rem/Exped ',
        type: 'text',
        model: '2ndRemExped',
        size:4
      },
      {
        label: '3rd Rem/Exped ',
        type: 'text',
        model: '3rdRemExped',
        size:4
      },
      {
        label: 'Country Of Origin ',
        type: 'text',
        model: 'Country',
        size:4
      },
      {
        label: 'Region',
        type: 'text',
        model: 'Region',
        size:4
      },
      {
        label: 'Order Unit',
        type: 'text',
        model: 'orderUnit',
        size:4
      },
    ],
   
  };
  